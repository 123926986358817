import React, { useState, useCallback, useEffect, useRef } from 'react';
import nprogress from 'nprogress';
import { ClientCompany } from 'services';

import { useReactToPrint } from 'react-to-print';
import FileViewer from 'react-file-viewer';

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useForm } from 'react-hook-form';

import {
  useToast,
  Button,
  ButtonGroup,
  VStack,
  Badge,
  chakra,
  Box,
  Icon,
  Text,
  HStack,
  Stack,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link as LinkChakra,
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import slugify from 'react-slugify';
import Modal from 'components/Modal';
import { Input } from 'components/Forms/Input';
import { Radio } from 'components/Forms/Radio';
import { Textarea } from 'components/Forms/Textarea';

import { RiMore2Fill, RiEdit2Fill, RiDeleteBin2Fill, RiDownload2Fill } from 'react-icons/ri';
import { FiImage, FiFile } from 'react-icons/fi';
import { FcFolder } from 'react-icons/fc';

import {
  GrDocumentPdf,
  GrDocumentZip,
  GrDocumentTxt,
  GrDocumentWord,
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentPpt,
  GrDocumentVideo,
  GrDocumentSound,
  GrDocumentRtf,
} from 'react-icons/gr';

import Delete from '../documents/delete';

import { useCan } from 'hooks/useCan';
import CnpjCard from './cnpjCard';
import { Link } from 'react-router-dom';
import Reset from './reset';

export default ({
  companyId,
  companyUf,
  licenseId,
  companyName,
  dataItem,
  isOpenLicense,
  onCloseLicense = () => {},
}) => {
  const componentRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [document, setDocument] = useState('');

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [filesLicenses, setFilesLicenses] = useState([]);
  const [fileFeeRef, setFileFeeRef] = useState('');
  const [fileRef, setFileRef] = useState('');
  const [newFileFeeName, setNewFileFeeName] = useState('');
  const [newFileName, setNewFileName] = useState('');
  const econformCan = useCan({ access_role: ['ECONFORM'] });
  const editorCan = useCan({ access_role: ['EDITOR'] });
  const deleteFilesCan = useCan({ permission_role: ['DELETE_FILES'] });
  const [resetItem, setResetItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm(/* { resolver: yupResolver(schema) } */);
  const [haveFee, setHaveFee] = useState('');
  const [accessoryObligations, setAccessoryObligations] = useState(haveFee);
  /* { resolver: yupResolver(schema) } */

  const handleConstruct = useCallback(async () => {
    setLoading(true);
    try {
      nprogress.start();
      const { data } = await ClientCompany.licenseRead(companyId, licenseId);
      nprogress.done();
      console.log('LICENSE-data: ', data);
      setHaveFee(String(Number(!!data.conditions)));
      reset({
        ...data,
        haveValidity: Number(!!data?.validUntil),
        validUntil: data?.validUntil?.slice(0, 10),
        accessoryObligations: Number(!!data?.feeAmount),
        feeExpirationDate: data.feeExpirationDate?.slice(0, 10),
      });
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar a licença/certidão.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
    setLoading(false);
  }, [companyId, licenseId]);

  const getFiles = async () => {
    try {
      const { data } = await ClientCompany.fileIndex(
        companyId,
        dataItem.clientNationalRegistrationId,
        dataItem.fiscalLicenseId
      );
      setFilesLicenses(data);
    } catch (error) {
      toast({
        title: 'Não foi possível carregar os documentos.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    },
  });

  const onChangeInputFileFee = (e) => {
    setFileFeeRef(e.target.files[0]);
    let file = e.target.files[0].name.toLowerCase();
    let filenameSeparated = file.split('.');
    let extension = filenameSeparated.pop();
    let filename = file
      .replace('.' + extension, '')
      .replace(' ', '-')
      .replace('.', '-');
    setNewFileFeeName(slugify(filename) + '.' + extension);
  };

  const onChangeInputFile = (e) => {
    setFileRef(e.target.files[0]);
    let file = e.target.files[0].name.toLowerCase();
    let filenameSeparated = file.split('.');
    let extension = filenameSeparated.pop();
    let filename = file
      .replace('.' + extension, '')
      .replace(' ', '-')
      .replace('.', '-');
    setNewFileName(slugify(filename) + '.' + extension);
  };

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        const envFolder = process.env.REACT_APP_ENVIRONMENT == 'prod' ? 'production/' : 'dev/';

        const fileFeeKey = `${
          envFolder +
          process.env.REACT_APP_AWS_S3_DIR_NAME_DOCUMENTS +
          '/' +
          dataItem.clientNationalRegistrationCode.replace(/\s/g, '') +
          '/' +
          slugify(dataItem.fiscalRegistrationCodeClass).replace(/\D/g, '') +
          '/' +
          dataItem.fiscalLicenseId.replace(/\s/g, '') +
          '/' +
          newFileFeeName
        }`;

        const fileKey = `${
          envFolder +
          process.env.REACT_APP_AWS_S3_DIR_NAME_DOCUMENTS +
          '/' +
          dataItem.clientNationalRegistrationCode.replace(/\s/g, '') +
          '/' +
          slugify(dataItem.fiscalRegistrationCodeClass).replace(/\D/g, '') +
          '/' +
          dataItem.fiscalLicenseId.replace(/\s/g, '') +
          '/' +
          newFileName
        }`;
        await ClientCompany.licenseUpdateItem(companyId, {
          fiscalLicense: dataItem.fiscalLicenseId,
          clientNationalRegistrationId: dataItem.clientNationalRegistrationId,
          fiscalRegistrationId: dataItem.fiscalRegistrationId,

          validUntil: data.validUntil,
          file: newFileName || data?.file || null,
          type: data.type,
          feeAmount: data.feeAmount,
          feeExpirationDate: data?.feeExpirationDate,
          conditions: data.conditions,
          feeName: data.feeName,
          note: data.note,
          feeFile: newFileFeeName || data?.feeFile || null,

          isNecessary: data.isNecessary === 'true' ? true : false,
          problem: data.problem,
          description: data.description,
        });

        const uploadFeeParams = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: fileFeeKey,
          Body: fileFeeRef,
        };
        await s3.send(new PutObjectCommand(uploadFeeParams));

        const uploadParams = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: fileKey,
          Body: fileRef,
        };
        await s3.send(new PutObjectCommand(uploadParams));

        toast({
          title: 'Alteração realizada',
          status: 'success',
          isClosable: true,
        });
        getFiles();
        setTimeout(() => {
          setNewFileName('');
          setNewFileFeeName('');
          onCloseLicense();
        }, 1000);
      } catch (error) {
        console.log('ERRO AO SALVAR ', error.response.data);
        toast({
          title: 'Não foi possível alterar',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [companyId, dataItem, newFileName, fileRef, newFileFeeName, fileFeeRef, s3, onClose]
  );

  useEffect(() => {
    if ((dataItem.id || 0) > 0) {
      handleConstruct();
      getFiles();
    }
  }, [handleConstruct]);

  const badgeStatus =
    watch('status') &&
    (watch('status') === 'in_conformity' ? (
      <Badge colorScheme="green">Em conformidade</Badge>
    ) : watch('status') === 'pending' ? (
      <Badge colorScheme="yellow">Pendente</Badge>
    ) : watch('status') === 'license_expired' ? (
      <Badge colorScheme="red">Vencida</Badge>
    ) : watch('status') === 'near_expiration' ? (
      <Badge colorScheme="purple">Expirando</Badge>
    ) : watch('status') === 'renewing' ? (
      <Badge colorScheme="blue">Renovando</Badge>
    ) : watch('status') === 'not_necessary' ? (
      <Badge colorScheme="grayBlue">Não necessária</Badge>
    ) : (
      ''
    ));

  function IconFile({ extension }) {
    return (
      <Box mr="4" align="center">
        {extension === 'folder' ? (
          <Icon as={FcFolder} boxSize="2rem" />
        ) : extension === 'jpg' ||
          extension === 'jpeg' ||
          extension === 'png' ||
          extension === 'gif' ||
          extension === 'bmp' ||
          extension === 'tiff' ||
          extension === 'cdr' ||
          extension === 'ai' ? (
          <Icon as={FiImage} boxSize="2rem" color="orange.400" />
        ) : extension === 'pdf' ? (
          <Icon as={GrDocumentPdf} boxSize="2rem" color="red.400" sx={{ path: { stroke: 'red.500' } }} />
        ) : extension === 'zip' ? (
          <Icon as={GrDocumentZip} boxSize="2rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'csv' ? (
          <Icon as={GrDocumentCsv} boxSize="2rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'rtf' ? (
          <Icon as={GrDocumentRtf} boxSize="2rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'doc' || extension === 'docx' ? (
          <Icon as={GrDocumentWord} boxSize="2rem" color="blue.500" sx={{ path: { stroke: 'blue.500' } }} />
        ) : extension === 'xls' || extension === 'xlsx' || extension === 'xlsb' ? (
          <Icon as={GrDocumentExcel} boxSize="2rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'ppt' || extension === 'pptx' ? (
          <Icon as={GrDocumentPpt} boxSize="2rem" color="pink.400" sx={{ path: { stroke: 'pink.400' } }} />
        ) : extension === 'mp4' ||
          extension === 'mpeg' ||
          extension === 'avi' ||
          extension === 'mpg' ||
          extension === 'mpg' ||
          extension === 'mov' ? (
          <Icon as={GrDocumentVideo} boxSize="2rem" color="purple.400" sx={{ path: { stroke: 'purple.400' } }} />
        ) : extension === 'mp3' || extension === 'wav' || extension === 'ogg' || extension === 'wma' ? (
          <Icon as={GrDocumentSound} boxSize="2rem" color="orange.400" sx={{ path: { stroke: 'orange.400' } }} />
        ) : extension === 'txt' ? (
          <Icon as={GrDocumentTxt} boxSize="2rem" color="gray.600" sx={{ path: { stroke: 'gray.600' } }} />
        ) : (
          <Icon as={FiFile} boxSize="2rem" color="gray.400" sx={{ path: { stroke: 'gray.400' } }} />
        )}
      </Box>
    );
  }
  function BoxFile({ id, name, extension, href, type, status }) {
    return (
      <HStack
        key={id}
        borderRadius="3px"
        backgroundColor="grayBlue.50"
        p="4"
        cursor="pointer"
        _hover={{ backgroundColor: 'grayBlue.200' }}
        title={name}
        align="center"
        justify="space-between"
      >
        <HStack
          align="center"
          onClick={() => {
            setDocument({ id, name, href, status, type });
            onOpen();
          }}
        >
          <IconFile extension={extension} />
          <VStack spacing="0.5" align="start">
            <Text fontSize="xs" color="grayBlue.800" fontWeight="semibold" noOfLines={3} textTransform="capitalize">
              {name}
            </Text>
          </VStack>
        </HStack>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={RiMore2Fill} w={6} h={6} />}
              variant="unstyle"
              minW="auto"
              color="grayBlue.400"
              _hover={{ color: 'blue.400' }}
            />
            <MenuList>
              <MenuItem icon={<RiEdit2Fill />} onClick={() => window.open(href, '_blank')}>
                Abrir
              </MenuItem>
              <MenuItem icon={<RiDeleteBin2Fill />} onClick={() => setDeleteItem(Number(id))} color="red.500">
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    );
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{}',
    copyStyles: true,
    /* fonts: {
      family: "'Roboto', sans-serif",
      source: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap";
    }[], */
  });

  const linksAndFonts = [
    {
      uf: 'SP',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consulta Pública de Licenciamento',
              link: 'https://www.jucesp.sp.gov.br/IntegradorPaulista/ConsultaPublica',
            },
          ],
        },
        {
          name: 'Prefeitura',
          links: [
            {
              name: 'Consulta Pública de Licença Municipal',
              link: 'https://e-licenca.prefeitura.sp.gov.br/LicenciamentoInternet/ConsultaLicenca',
            },
          ],
        },
        {
          name: 'Bombeiro',
          links: [
            {
              name: 'Consulta Licenças "por endereço"',
              link: 'https://viafacil2.policiamilitar.sp.gov.br/sgsci/publico/pesquisaravcblogradouro.aspx',
            },
          ],
        },
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consulta Licenças "por endereço"',
              link: 'https://vreredesim.sp.gov.br/home',
            },
          ],
        },
      ],
    },
    {
      uf: 'ES',
      items: [
        {
          name: 'Simplifica',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.simplifica.es.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PB',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.redesim.pb.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PR',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.pr.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'GO',
      items: [
        {
          name: 'Portal do Empreendedor Goiano',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.portaldoempreendedorgoiano.go.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MA',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.ma.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RO',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.ro.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MG',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesim.mg.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PI',
      items: [
        {
          name: 'Piaui Digital',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.piauidigital.pi.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RN',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.redesim.rn.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AL',
      items: [
        {
          name: 'Facilita',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.facilita.al.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PE',
      items: [
        {
          name: 'Jucepe',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://portal.jucepe.pe.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AM',
      items: [
        {
          name: 'Jucea',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://jucea.am.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AC',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.ac.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RS',
      items: [
        {
          name: 'JucisRS',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://jucisrs.rs.gov.br/inicial',
            },
          ],
        },
      ],
    },
    {
      uf: 'BA',
      items: [
        {
          name: 'Juceb',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://www.juceb.ba.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PA',
      items: [
        {
          name: 'Jucepa',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://jucepa.pa.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'TO',
      items: [
        {
          name: 'Simplifica',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.simplifica.to.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'SE',
      items: [
        {
          name: 'Agiliza',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.agiliza.se.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'CE',
      items: [
        {
          name: 'Jucec',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucec.ce.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MT',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.mt.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MS',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.ms.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RJ',
      items: [
        {
          name: 'Jucerja',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucerja.rj.gov.br/',
            },
            {
              name: 'Portal Carioca Digital',
              link: 'https://carioca.rio',
            },
          ],
        },
      ],
    },
    {
      uf: 'DF',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.df.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'SC',
      items: [
        {
          name: 'Jucesc',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucesc.sc.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RR',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.rr.gov.br/',
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        title={
          <>
            {dataItem?.fiscalLicenseName} {badgeStatus}
          </>
        }
        size="lg"
        isOpen={isOpenLicense}
        onClose={onCloseLicense}
        loading={loading}
        isSubmitting={isSubmitting}
        footer={
          <ButtonGroup spacing="4">
            <Button type="submit" form="formRef" isLoading={isSubmitting}>
              Salvar
            </Button>
            <Button colorScheme="red" onClick={() => setResetItem(Number(licenseId))} isLoading={isSubmitting}>
              Resetar
            </Button>
            <Button
              colorScheme="grayBlue"
              variant="ghost"
              onClick={() => {
                reset({
                  fiscalLicense: null,
                  validUntil: null,
                  file: null,
                });
                onCloseLicense();
              }}
            >
              Fechar
            </Button>
          </ButtonGroup>
        }
      >
        <VStack as="form" id="formRef" w="100%" onSubmit={handleSubmit(handleOnSubmit)} spacing={10} mb={4}>
          <Box w="100%" p={8} bg="grayBlue.200" borderRadius="5px">
            <Radio
              name="isNecessary"
              label="É uma licença/certidão necessária?"
              defaultValue={dataItem?.status === 'pending' ? 'true' : String(watch('isNecessary'))}
              options={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false', onClick: () => setTabIndex(0) },
              ]}
              errors={errors}
              variant="unstyled"
              register={register}
            />
          </Box>

          <Tabs index={tabIndex} onChange={handleTabsChange} variant="enclosed" w="100%">
            <TabList>
              <Tab _focus={{ boxShadow: 'none' }}>DADOS</Tab>
              <Tab
                _focus={{ boxShadow: 'none' }}
                isDisabled={watch('isNecessary') === 'false'}
                color={watch('isNecessary') === 'false' && 'grayBlue.400'}
              >
                CONDICIONANTES
              </Tab>
              <Tab _focus={{ boxShadow: 'none' }}>ARQUIVOS</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                {/* DADOS */}
                <VStack
                  gap={8}
                  align="left"
                  w="100%"
                  p={8}
                  borderRadius="5px"
                  borderTopRadius={0}
                  border="1px"
                  borderColor="grayBlue.300"
                  borderTop="none"
                >
                  {/* <Text
                    fontWeight="semibold"
                    fontSize="xl"
                    textTransform={'uppercase'}
                    color="blue.500"
                    textAlign="left"
                  >
                    DADOS
                  </Text> */}

                  {watch('isNecessary') === 'true' ? (
                    <>
                      <></>
                      <Radio
                        name="type"
                        label="Tipo de cadastro"
                        defaultValue={getValues('type')}
                        options={[
                          { label: 'Licença/Certidão', value: 'document' },
                          { label: 'Protocolo', value: 'protocol' },
                        ]}
                        errors={errors}
                        variant="unstyled"
                        register={register}
                      />

                      <Radio
                        name="haveValidity"
                        label="Tem validade?"
                        defaultValue={dataItem?.status === 'pending' ? '' : String(Number(!!dataItem.validUntil))}
                        options={[
                          { label: 'Sim', value: '1' },
                          { label: 'Não', value: '0' },
                        ]}
                        errors={errors}
                        variant="unstyled"
                        register={register}
                      />

                      {watch('haveValidity') != '0' && (
                        <>
                          <Input
                            name="validUntil"
                            type="date"
                            label={`Validade`}
                            disabled={watch('haveValidity') == '0'}
                            errors={errors}
                            register={register}
                          />
                        </>
                      )}
                      {/* <div>
                        <Input
                          name="file"
                          type="file"
                          label="Arquivo da licença/certidão"
                          onChange={onChangeInputFile}
                          errors={errors}
                          register={register}
                        />
                        {watch('files')?.documents?.length > 0 ||
                        watch('files')?.fees?.length > 0 ||
                        watch('files')?.protocols?.length > 0 ? (
                          <SimpleGrid columns={[1]} spacing="4" mt={2}>
                            {watch('files')?.documents?.map((doc) => (
                              <BoxFile
                                id={doc?.id}
                                name={doc?.name}
                                extension={doc?.name?.split('.').pop()}
                                href={doc?.href}
                                type={doc?.file_type}
                              />
                            ))}
                            {watch('files')?.fees?.map((doc) => (
                              <BoxFile
                                id={doc?.id}
                                name={doc?.name}
                                extension={doc?.name?.split('.').pop()}
                                href={doc?.href}
                                type={doc?.file_type}
                              />
                            ))}
                            {watch('files')?.protocols?.map((doc) => (
                              <BoxFile
                                id={doc?.id}
                                name={doc?.name}
                                extension={doc?.name?.split('.').pop()}
                                href={doc?.href}
                                type={doc?.file_type}
                              />
                            ))}
                          </SimpleGrid>
                        ) : dataItem?.fiscalLicenseName?.includes('CNPJ') ? (
                          <Box w="50%">
                            <BoxFile id={0} name={'Cartão CNPJ'} extension={'pdf'} href={'#'} type={'document'} />
                          </Box>
                        ) : (
                          <Text fontSize="xs" fontWeight="medium" mt={2} color="grayBlue.400">
                            Nenhum arquivo encontrado
                          </Text>
                        )}
                      </div> */}
                    </>
                  ) : (
                    <>
                      <Radio
                        name="problem"
                        label="Não necessário em função de imóvel localizado em:"
                        defaultValue={dataItem.problem}
                        options={[
                          { label: 'Shopping', value: 'shopping' },
                          { label: 'Centro comercial', value: 'centro_comercial' },
                          { label: 'Residencial', value: 'residencial' },
                          { label: 'Logradouro', value: 'logradouro' },
                          { label: 'Sala comercial', value: 'sala_comercial' },
                          { label: 'Operador logístico', value: 'operador_logistico' },
                        ]}
                        errors={errors}
                        variant="unstyled"
                        register={register}
                      />
                      <Textarea
                        name="description"
                        label="Outros motivos:"
                        placeholder="Descreva aqui..."
                        errors={errors}
                        variant="unstyled"
                        register={register}
                      />
                    </>
                  )}
                </VStack>
              </TabPanel>

              <TabPanel p={0}>
                {/* CONDICIONANTES */}
                {watch('isNecessary') === 'true' && (
                  <VStack
                    gap={8}
                    align="left"
                    w="100%"
                    fontWeight="semibold"
                    fontSize="lg"
                    color="grayBlue.500"
                    textAlign="left"
                    p={8}
                    borderRadius="5px"
                    borderTopRadius={0}
                    border="1px"
                    borderColor="grayBlue.300"
                    borderTop="none"
                  >
                    <Text
                      fontWeight="semibold"
                      fontSize="xl"
                      textTransform={'uppercase'}
                      color="blue.500"
                      textAlign="left"
                    >
                      Condicionantes
                    </Text>
                    <Radio
                      name="haveFee"
                      label="Possui condicionantes?"
                      defaultValue={String(Number(!!watch('conditions')))}
                      options={[
                        { label: 'Sim', value: '1' },
                        { label: 'Não', value: '0' },
                      ]}
                      onChange={(e) => setHaveFee(e.target.value)}
                      errors={errors}
                      variant="unstyled"
                      register={register}
                    />

                    {haveFee != '0' && (
                      <>
                        <Textarea
                          name="conditions"
                          label="Condicionantes"
                          isDisabled={haveFee == '0'}
                          placeholder="Descreva aqui..."
                          errors={errors}
                          register={register}
                        />

                        <Radio
                          name="accessoryObligations"
                          label="É necessário recolhimento de taxa?"
                          isDisabled={haveFee == '0'}
                          defaultValue={dataItem?.status === 'pending' ? '0' : String(Number(!!dataItem?.feeAmount))}
                          options={[
                            { label: 'Sim', value: '1' },
                            { label: 'Não', value: '0' },
                          ]}
                          onChange={(e) => setAccessoryObligations(e.target.value)}
                          errors={errors}
                          variant="unstyled"
                          register={register}
                        />

                        <Input
                          name="feeName"
                          label="Nome da taxa"
                          disabled={haveFee == '0' || accessoryObligations == '0'}
                          errors={errors}
                          register={register}
                        />

                        <Input
                          name="feeAmount"
                          label="Valor da taxa"
                          disabled={haveFee == '0' || accessoryObligations == '0'}
                          errors={errors}
                          register={register}
                        />

                        <Input
                          name="feeExpirationDate"
                          type="date"
                          label="Vencimento da taxa"
                          disabled={haveFee == '0' || accessoryObligations == '0'}
                          errors={errors}
                          register={register}
                        />

                        <Input
                          name="feeFile"
                          type="file"
                          label="Arquivo da Taxa"
                          disabled={haveFee == '0' || accessoryObligations == '0'}
                          onChange={onChangeInputFileFee}
                          errors={errors}
                          register={register}
                        />
                      </>
                    )}

                    <Textarea
                      name="note"
                      label="Observações"
                      disabled={haveFee == '0' || accessoryObligations == '0'}
                      placeholder="Descreva aqui..."
                      errors={errors}
                      register={register}
                    />
                  </VStack>
                )}
              </TabPanel>

              <TabPanel p={0}>
                {/* ARQUIVOS */}
                <Box
                  p={8}
                  borderRadius="5px"
                  borderTopRadius={0}
                  border="1px"
                  borderColor="grayBlue.300"
                  borderTop="none"
                >
                  {(econformCan || editorCan) && (
                    <>
                      <Text fontWeight="semibold" fontSize="md" color="grayBlue.500" textAlign="left">
                        Links e fontes - {companyUf}
                      </Text>
                      <VStack mb="8" align="start" spacing="0">
                        {/* {companyUf === 'SP' && (
                          <>
                            <div>
                              <chakra.span color="grayBlue.500">RedeSim: </chakra.span>
                              <LinkChakra
                                href="https://www.jucesp.sp.gov.br/IntegradorPaulista/ConsultaPublica"
                                isExternal
                              >
                                Consulta Pública de Licenciamento
                              </LinkChakra>
                            </div>
                            <div>
                              <chakra.span color="grayBlue.500">Prefeitura: </chakra.span>
                              <LinkChakra
                                href="https://e-licenca.prefeitura.sp.gov.br/LicenciamentoInternet/ConsultaLicenca"
                                isExternal
                              >
                                Consulta Pública de Licença Municipal
                              </LinkChakra>
                            </div>
                            <div>
                              <chakra.span color="grayBlue.500">Bombeiro: </chakra.span>
                              <LinkChakra
                                href="https://viafacil2.policiamilitar.sp.gov.br/sgsci/publico/pesquisaravcblogradouro.aspx"
                                isExternal
                              >
                                Consulta Licenças "por endereço"
                              </LinkChakra>
                            </div>
                          </>
                        )} */}
                        {linksAndFonts?.map(
                          (linkfont) =>
                            companyUf === linkfont?.uf &&
                            linkfont?.items?.map((item, i) => (
                              <div key={i}>
                                <chakra.span color="grayBlue.500">{item?.name}: </chakra.span>
                                {item?.links?.map((link, idx) => (
                                  <LinkChakra key={idx} href={link?.link} isExternal>
                                    {link?.name}
                                  </LinkChakra>
                                ))}
                              </div>
                            ))
                        )}
                      </VStack>
                    </>
                  )}

                  <Input
                    name="file"
                    type="file"
                    label="Enviar"
                    onChange={onChangeInputFile}
                    errors={errors}
                    register={register}
                  />

                  <Text fontWeight="semibold" fontSize="md" color="grayBlue.500" textAlign="left" mt="8">
                    Enviados
                  </Text>
                  {watch('files')?.documents?.length > 0 ||
                  watch('files')?.fees?.length > 0 ||
                  watch('files')?.protocols?.length > 0 ? (
                    <SimpleGrid columns={[1]} spacing="4">
                      {watch('files')?.documents?.map((doc) => (
                        <BoxFile
                          id={doc?.id}
                          name={doc?.name}
                          extension={doc?.name?.split('.').pop()}
                          href={doc?.href}
                          type={doc?.file_type}
                        />
                      ))}
                      {watch('files')?.fees?.map((doc) => (
                        <BoxFile
                          id={doc?.id}
                          name={doc?.name}
                          extension={doc?.name?.split('.').pop()}
                          href={doc?.href}
                          type={doc?.file_type}
                        />
                      ))}
                      {watch('files')?.protocols?.map((doc) => (
                        <BoxFile
                          id={doc?.id}
                          name={doc?.name}
                          extension={doc?.name?.split('.').pop()}
                          href={doc?.href}
                          type={doc?.file_type}
                        />
                      ))}
                    </SimpleGrid>
                  ) : dataItem?.fiscalLicenseName?.includes('CNPJ') ? (
                    <Box w="100%">
                      <BoxFile id={0} name={'Cartão CNPJ'} extension={'pdf'} href={'#'} type={'document'} />
                    </Box>
                  ) : (
                    <Text fontSize="xs" fontWeight="medium" mt={4} color="grayBlue.400">
                      Nenhum arquivo encontrado
                    </Text>
                  )}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Modal>

      <ModalChakra isOpen={isOpen} onClose={onClose} size="xxl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <span>
                {document?.type === 'document' ? 'Licença' : document?.type === 'protocol' ? 'Protocolo' : 'Taxa'}:{' '}
              </span>
              <IconFile extension={document?.name?.includes('CNPJ') ? 'pdf' : document?.name?.split('.').pop()} />

              <span>
                {document?.name} {badgeStatus}
              </span>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="grayBlue.200">
            <Box mx="auto" textAlign="center">
              {document?.name?.includes('CNPJ') ? (
                <>
                  <Text mb="6" textAlign="left" color="blue.500" textTransform="capitalize">
                    <Button onClick={handlePrint} size="sm" variant="link">
                      Download/Imprimir
                    </Button>
                  </Text>
                  <CnpjCard ref={componentRef} companyId={companyId} document={document} />
                </>
              ) : (
                <>
                  <Text mb="6" color="blue.500" textTransform="capitalize" textAlign="left">
                    <Button as={Link} to={document?.href} target="_blank" size="sm" variant="link">
                      Download
                    </Button>
                  </Text>
                  <Box minHeight="200px">
                    {document?.name?.split('.').pop() === 'xlsb' ? (
                      'Este arquivo Excel contém filtros e códigos avançados para a exibição completa por aqui. Faça download e abra o mesmo localmente em seu sistema operacional.'
                    ) : (
                      <FileViewer
                        fileType={document?.name?.split('.').pop()}
                        filePath={document?.href}
                        styles={{ width: 'auto!important', minHeight: '300px!important' }}
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalChakra>

      {deleteFilesCan ? (
        <Reset
          id={resetItem}
          companyId={companyId}
          isOpen={!!resetItem}
          onClose={() => {
            setResetItem(0);
          }}
        />
      ) : null}

      {deleteFilesCan ? (
        <Delete
          id={deleteItem}
          companyId={companyId}
          isOpen={!!deleteItem}
          onClose={() => {
            setDeleteItem(0);
            getFiles();
          }}
        />
      ) : null}
    </>
  );
};
